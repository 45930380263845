import React, { useEffect } from 'react';

const Login = ({
    onIsEntryOpen,
    onDataUserAuth,
    dataUserAuth,
    onIsRegisterClose,
    onSubmitAuth
}) => {


    // ПРИ ОТРИСОВКЕ КОМПОНЕНТА МЕНЯЕМ СТАТУСЫ СТЭЙТОВ
    useEffect(() => {
        onIsRegisterClose();
        onIsEntryOpen();
    }, [])


    return (
        // ЕСЛИ В localStorage ИМЕЕТСЯ ТОКЕН, ТО ФОРМА С АВТОРИЗАЦИЕЙ ПОКАЗЫВАТЬСЯ НЕ БУДЕТ
        // ЭТО УСЛОВИЕ НУЖНО ПРИ ОБНОВЛЕНИИ СТРАНИЦЫ, ЧТОБЫ НЕ МЕЛЬКАЛА ФОРМА АВТОРИЗАЦИИ 
        // ПОКА НЕ ПОЛУЧИМ ОТВЕТ ОТ СЕРВЕРА, ЧТО ТОКЕН ВАЛИДНЫЙ
        <div className={`login ${localStorage.getItem('userEmail') && 'login__not-display'}`}>

            <h2 className="login__title">Вход</h2>

            <form onSubmit={onSubmitAuth} className="login__container-input">

                <label className="popup__box-input-span">
                    <input value={dataUserAuth.email} onChange={onDataUserAuth} type="email" id="email" name="email" className="login__input" placeholder="Email" autoComplete="off"></input>
                    <span className="popup__input-error email-auth-error"></span>
                </label>

                <label className="popup__box-input-span">
                    <input value={dataUserAuth.password} onChange={onDataUserAuth} type="password" id="password" className="login__input" name="password" minLength={5} placeholder="Пароль" autoComplete="off"></input>
                    <span className="popup__input-error password-auth-error"></span>
                </label>

                <button className="login__button">Войти</button>

            </form>
        </div>

    )
}

export default Login;