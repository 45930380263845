import React from 'react';
import successIcon from './../images/success.svg'
import failIcon from './../images/fail.svg'

const InfoToolTip = ({ isInfoToolTip, onClose }) => {


    // ОПРЕДЕЛЕНИЕ ИКОНКИ ИНФОРМАЦИИ
    function chooseIcon(first, second) {
        if (isInfoToolTip.image === 'success') {
            return first;
        } else {
            return second;
        }
    }

    return (
        <div className={`${"popup"} ${isInfoToolTip.open && "popup_opened"}`} >
            <div className="popup__window" >
                <button onClick={onClose} type="reset" className="popup__button-close"></button>
                <div className="popup__box-success-title">
                    <img src={chooseIcon(successIcon, failIcon)} alt={chooseIcon("Иконка успешной регистрации", "Иконка возникшей ошибки")} />
                    <p className="popup__title">{isInfoToolTip.error ? "Что-то пошло не так! Попробуйте ещё раз." : "Вы успешно зарегистрировались!"}</p>
                </div>
            </div>
        </div>
    )
}

export default InfoToolTip;