import React from 'react'

function PopupWithForm({ title, name, children, isOpen, onClose, onSubmit, buttonText }) {


    return (

        <div className={`popup popup_type_${name} ${isOpen && 'popup_opened'}`} >

            <div className="popup__window">
                <button onClick={onClose} type="reset" className="popup__button-close"></button>
                <h3 className="popup__title">{title}</h3>

                <form onSubmit={onSubmit} className="popup__form" method="POST" name={name}>
                    {/* children - ВСТАВЛЯЕТ НУЖНОЕ КОЛИЧЕСТВО ПОЛЕЙ ДЛЯ ОПРЕДЕЛЁННОЙ ФОРМЫ */}
                    {children}
                    <button type="submit" className="popup__button-save">{buttonText}</button>
                </form>

            </div>
        </div>

    )
}

export default PopupWithForm;

/* <form onSubmit={onSubmitRegistration} className="popup__form">
<label className="popup__box-input-span">
                <input value={dataUserRegister.email} onChange={onDataUserRegister} id="popup-name" autoComplete="off" type="text" className="popup__input" placeholder="Email"
                    name="email" minLength="2" maxLength="40" required />
                <span className="popup__input-error popup-name-error"></span>
</label>
            <label className="popup__box-input-span">
                <input value={dataUserRegister.password} onChange={onDataUserRegister} id="popup-status" autoComplete="off" type="text" className="popup__input" placeholder="Пароль"
                    name="password" minLength="2" maxLength="200" required />
                <span className="popup__input-error popup-status-error"></span>
            </label>
            <button type="submit" className="popup__button-save">Зарегистрироваться</button>
</form > */